import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'

import Breadcrumb from '../components/Breadcrumb'
import Layout from '../components/layout'
import Arrow from '../components/Arrow'

import styled from 'styled-components'

export const Wrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;

  padding: 2rem 0;
  min-height: 45vh;

  .wpcf7-form {
    label {
      display: block;

      span {
        display: block;
      }
    }
  }
`

export const Content = styled.div`
  width: ${({ sideBar }) => console.log(sideBar) || (sideBar ? '68%' : '100%')};
  margin-bottom: 3rem;

  h3 {
    font-size: 30px;
    margin-top: 1rem;
    color: #1f1414;
    letter-spacing: 0;

    font-weight: 500 !important;

    strong {
      font-weight: 500 !important;
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: 18px;

    &:first-child {
      margin-top: 0;
    }
  }
`

export const Boom = styled.section`
  background: ${props =>
    `linear-gradient(
      -180deg,
      rgba(20, 32, 54, 0.3) 0%,
      rgba(20, 32, 54, 0) 36%
    ), url('${props.backgroundImage}') center center no-repeat` || '#ccc'};
  background-size: cover;

  position: relative;

  .overlay {
    background: linear-gradient(
        0deg,
        rgba(20, 32, 54, 0.25) 0%,
        rgba(20, 32, 54, 0) 56%
      ),
      rgba(0, 47, 134, 0.2);

    /* position: absolute; */
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  h1 {
    /* margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    font-size: 54px;
    font-weight: bold; */

    margin: 0;

    text-transform: uppercase;
    font-size: 42px;
    color: #ffffff;
    letter-spacing: 5.44px;
  }

  .popover {
    position: absolute;
    bottom: 0px;
    left: 62%;
    padding: 1rem;
    color: #fff;
    background: #151b21;
    font-size: 16px;

    width: 425px;

    li {
      list-style: none;
      margin-bottom: 1rem;

      svg {
        fill: #fff;
        margin-right: 15px;
        top: 6px;
        position: relative;
      }
    }
  }

  .inner {
    max-width: 1200px;
    margin: 0 auto;

    padding: 3rem 0;
    color: #fff;

    min-height: 450px;

    display: flex;
    align-items: flex-end;
  }
`

export const getImage = image => {
  if (image) return image.source_url
  // if (image && image.localFile !== null)
  //   return image.localFile.childImageSharp.fluid
  return ''
}

export default ({ data }) => {
  const page = data.wordpressPage

  console.log(page)

  // console.log(getImage(page.featured_media))
  // console.log(page.acf)
  return (
    <Layout>
      <SEO title={page.title} />

      <Boom backgroundImage={getImage(page.featured_media)}>
        <div className="overlay">
          {/* <Img fluid={getImage(page.featured_media)} /> */}
          <div className="inner">
            <h1>{page.title}</h1>
          </div>

          {/* {page.acf !== null && page.acf.focus && (
            <div className="popover">
              {page.acf.focus.map(({ text }) => (
                <li key={text}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                  </svg>

                  {text}
                </li>
              ))}
            </div>
          )} */}
        </div>
      </Boom>
      <Breadcrumb>
        <Link to="/">Home</Link> <Arrow /> <b>{page.title}</b>
      </Breadcrumb>
      <Wrapper>
        <div>
          <Content
            className="content"
            // sideBar={
            //   page.acf.sidebar !== undefined &&
            //   (page.acf.sidebar !== '' && page.acf.sidebar !== null)
            // }
            dangerouslySetInnerHTML={{ __html: page.content }}
          />

          {/* {page.acf.blocks && page.acf.blocks.length !== 0 ? (
            <Blocks>
              {page.acf.blocks.map(
                ({ block, title, description, image, link }) => (
                  <Block key={title}>
                    <div className="content">
                      <h2>{title}</h2>

                      <p
                        className="innercontent"
                        dangerouslySetInnerHTML={{ __html: block }}
                      />
                    </div>
                    <Alt {...image}>
                      <div className="description">
                        &ldquo;{description}&rdquo;
                      </div>
                    </Alt>
                  </Block>
                )
              )}
            </Blocks>
          ) : null} */}

          {/* {page.acf.featured_slides !== null && (
            <Slideshow slides={page.acf.featured_slides} name={page.acf.name} />
          )} */}
        </div>
        {/* <Sidebar dangerouslySetInnerHTML={{ __html: page.acf.sidebar }} /> */}
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      slug
      # acf {
      #   sidebar
      #   header_description
      #   name
      #   blocks {
      #     block
      #     title
      #     description
      #     image {
      #       id
      #       source_url
      #     }
      #     # link
      #   }
      #   focus {
      #     text
      #   }
      #   featured_slides {
      #     title
      #     body
      #   }
      # }

      featured_media {
        id
        source_url
        localFile {
          id
          childImageSharp {
            id
            fluid {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }

      # featured_media {
      #   localFile {
      #     childImageSharp {
      #       fluid(maxHeight: 550) {
      #         ...GatsbyImageSharpFluid_noBase64
      #       }
      #     }
      #   }
      # }
      # featured_media {
      #   id
      #   slug
      #   link
      #   title
      #   alt_text
      #   source_url
      # }
    }
  }
`

// export const query = graphql`
//   query($slug: String!) {
//     wp {
//       pageBy(uri: $slug) {
//         id
//         title
//         link
//         uri
//         slug

//         content(format: RAW)

//         featuredImage {
//           caption
//           id
//           description
//           mediaType
//           mediaItemId
//           mediaDetails {
//             file
//             height
//             width
//           }
//           sourceUrl
//           slug
//           status
//           uri
//         }
//       }
//     }
//   }
// `
